import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IssTable from '../../common/IssTable';
import LineChart from '../../common/LineChart';
import BarChart from '../../common/BarChart';
import xlsx from 'json-as-xlsx';
import {
  beaconIssueHistory,
  dailyBeaconErrorAccumulated,
  dailyBeaconErrorOccurrence,
  dailyBeaconErrorStatus,
} from './IssueHistoryStatisticsData';
import Loading from '../../common/Loading';
import { ISS_BASE_URL } from '../../../../constants/index';
import { getHeaders } from '../../../../util/actionUtil';
import Alert from '../../modal/Alert';

function IssueHistoryBeaconCommon({ username, currentTab, currentFilter, downloadDataType, chartType }) {
  const [beaconListDisplay, setBeaconListDisplay] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Warning');
  const [alertMessage, setAlertMessage] = useState('');
  const [beaconHistoryDisplay, setBeaconHistoryDisplay] = useState(false);
  const [currentBeaconHistoryTableData, setCurrentBeaconHistoryTableData] = useState({});
  const [issueBeaconHistory, setIssueBeaconHistory] = useState([]);
  const [issueBeaconHistoryFetching, setIssueBeaconHistoryFetching] = useState('init');
  const lineChartType = 'Line';

  useEffect(() => {
    if (currentFilter && currentFilter.date.length > 0 && currentFilter.subType !== 'All') {
      initAndRequest();
      getBeaconHistoryInfo(currentFilter);
      getBeaconListInfo(currentFilter);
    }
  }, [currentFilter]);

  const initAndRequest = () => {
    setBeaconHistoryDisplay(false);
    setBeaconListDisplay(false);
    dailyBeaconErrorOccurrence.data.datasets[0].data = [];
    dailyBeaconErrorAccumulated.data.datasets[0].data = [];
    dailyBeaconErrorStatus.data.datasets[0].data = [];
    beaconIssueHistory.rowdata = [];
    setCurrentBeaconHistoryTableData(beaconIssueHistory);
  };

  const getBeaconHistoryInfo = filter => {
    let startDate = filter.date[0];
    let endDate = filter.date[1];

    let barChartBody = {
      startDate: startDate,
      endDate: endDate,
      groupList: filter.groupList,
      cpIdList: [],
      countryCodeList: [],
      platformList: [],
      detectionItemList: [],
      typeList: [],
    };

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/historyAllChart`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(barChartBody),
    })
    .then(res => res.json())
    .then(body => {
      if (body) {
        const beaconDailyIssueData = [];
        const beaconAccumulatedResolveRate = [];
        let beaconAccumulatedIssueCount = 0;
        let beaconAccumulatedResolvedIssueCount = 0;
        const beaconDailyResolveRate = [];
        const dates = Object.keys(body).sort();
        const convertDates = dates.map(v => {return v.substring(5,10)});

        for (let date of dates) {
          //Beacon Data
          beaconDailyIssueData.push(body[date]['raisedIssueBeaconCount']);
          beaconAccumulatedIssueCount += body[date]['raisedIssueBeaconCount'];
          beaconAccumulatedResolvedIssueCount += body[date]['resolvedIssueBeaconCount'];
          beaconAccumulatedResolveRate.push(beaconAccumulatedIssueCount === 0 ? 100 :
            Math.round( beaconAccumulatedResolvedIssueCount / beaconAccumulatedIssueCount * 100));
          beaconDailyResolveRate.push(body[date]['raisedIssueBeaconCount'] === 0 ? 100:
            Math.round( body[date]['resolvedIssueBeaconCount']/ body[date]['raisedIssueBeaconCount'] * 100));
        }
        const beaconMaxVal = getMultipleOf100GreaterThan(Math.max.apply(null, beaconDailyIssueData));
        //[Beacon Data] 1 chart
        dailyBeaconErrorOccurrence.data.labels = convertDates;
        dailyBeaconErrorOccurrence.data.datasets[0].data = beaconDailyIssueData;
        dailyBeaconErrorOccurrence.options.scales.y.max = beaconMaxVal;
        dailyBeaconErrorOccurrence.options.scales.y.ticks.stepSize = beaconMaxVal / 2;

        //[Beacon Data] 2 chart
        dailyBeaconErrorAccumulated.data.labels = convertDates;
        dailyBeaconErrorAccumulated.data.datasets[0].data = beaconAccumulatedResolveRate;

        //[Beacon Data] 3 chart
        dailyBeaconErrorStatus.data.labels = convertDates;
        dailyBeaconErrorStatus.data.datasets[0].data = beaconDailyResolveRate;
        setBeaconHistoryDisplay(true);
      } else {
        setAlertTitle('Error');
        setBeaconHistoryDisplay(false);
        setAlertMessage(`IssueHistoryStatisticsCommon error`);
        onOpenAlert();
      }
    })
    .catch(error => {
      console.log(`IssueHistoryStatisticsCommon error: `, error);
    }).finally(() => {});
  };

  const getBeaconListInfo = filter => {
    let startDate = filter.date[0];
    let endDate = filter.date[1];
    const userInfo = username === 'admin' ? 'cmpuser' : username;

    let beaconListBody = {
      startDate: startDate,
      endDate: endDate,
      groupList: filter.groupList,
      detectionItemList: [],
      statusList: [],
      userInfo: userInfo,
    };

    setIssueBeaconHistoryFetching('loading');

    fetch(`${ISS_BASE_URL}/api/statistics/dashboard/issueBeaconHistory`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(beaconListBody),
    })
    .then(res => res.json())
    .then(body => {
      setIssueBeaconHistoryFetching('done');
      setIssueBeaconHistory(body);
    })
    .catch(error => {
      console.log(`getBeaconListInfo error: `, error);
    }).finally(() => {});
  };

  useEffect(() => {
    if(issueBeaconHistory.length > 0) {
      beaconIssueHistory.rowdata = issueBeaconHistory.map(history => ({
        row: [
          history.issueId,
          history.issueCode,
          history.groupName,
          history.dataSourceType,
          history.detectionItem,
          history.status,
          history.assignGroup,
          history.causeName,
          history.causeProcessDetail,
          history.crtDate,
          history.lastChgDate,
          history.jiraUrl,
        ]
      }));
      setCurrentBeaconHistoryTableData(beaconIssueHistory);
    }
    if (issueBeaconHistoryFetching === 'done') {
      setBeaconListDisplay(true);
    }
}, [issueBeaconHistory]);

  useEffect(() => {
    if (downloadDataType === 'excel') {
      if (issueBeaconHistory.length > 0) {
        let data = [
          {
            sheet: `Beacon_Issue`,
            columns: [
              { label: 'Issue ID', value: (row) => row.issueId },
              { label: 'Issue Code', value: row => row.issueCode},
              { label: 'Group', value: row => row.groupName},
              { label: 'Data Source', value: row => row.dataSourceType},
              { label: 'Detection Item', value: row => row.detectionItem},
              { label: 'Status', value: row => row.status},
              { label: 'Assign Group', value: row => row.assignGroup},
              { label: 'Cause', value: row => row.causeName},
              { label: 'Action Details', value: row => row.causeProcessDetail},
              { label: 'Date', value: row => row.crtDate},
              { label: 'Change Date', value: row => row.lastChgDate.substring(0, 10)},
              { label: 'Jira URL', value: row => row.jiraComment ? row.jiraUrl + ', ' + row.jiraComment : row.jiraUrl},
            ],
            content: [...issueBeaconHistory],
          },
        ];
        let xLSettings = {
          fileName: `Beacon_Issue`,
        };
        xlsx(data, xLSettings);
      }
    }
  }, [downloadDataType, issueBeaconHistory]);

  const getMultipleOf100GreaterThan = (num) => {
    return num + (100 - num % 100);
  };

  const onOpenAlert = () => {
    setIsAlert(true);
  };

  const onCloseAlert = () => {
    setIsAlert(false);
  };

  return (
    <div>
      {beaconHistoryDisplay ? (
        <div className="grid-row">
          <div className="row box-sub">
            <div className="head title">
              <h2>Number of issues (based on occurrence date)</h2>
            </div>
            <div className="ui-chart-container">
              {chartType === lineChartType ? (
                <LineChart id={'daily_beacon_error_occurrence'} data={dailyBeaconErrorOccurrence.data}
                          options={dailyBeaconErrorOccurrence.options}/>
                ) : (
                <BarChart id={'daily_beacon_error_occurrence'} data={dailyBeaconErrorOccurrence.data}
                options={dailyBeaconErrorOccurrence.options}/>
              )}
            </div>
          </div>
          <div className="row box-sub">
            <div className="head title">
              <h2>Issue resolution rate (based on occurrence date)</h2>
            </div>
            <div className="ui-chart-container">
              {chartType === lineChartType ? (
                <LineChart id={'daily_beacon_error_accumulated'} data={dailyBeaconErrorAccumulated.data}
                          options={dailyBeaconErrorAccumulated.options}/>
                ) : (
                <BarChart id={'daily_beacon_error_accumulated'} data={dailyBeaconErrorAccumulated.data}
                options={dailyBeaconErrorAccumulated.options}/>
              )}
            </div>
          </div>
          <div className="row box-sub">
            <div className="head title">
              <h2>Issue resolution rate (based on cumulative daily date)</h2>
            </div>
            <div className="ui-chart-container">
              {chartType === lineChartType ? (
                <LineChart id={'daily_beacon_error_status'} data={dailyBeaconErrorStatus.data}
                          options={dailyBeaconErrorStatus.options}/>
                ) : (
                <BarChart id={'daily_beacon_error_status'} data={dailyBeaconErrorStatus.data}
                options={dailyBeaconErrorStatus.options}/>
              )}
            </div>
          </div>
            { beaconListDisplay && (
              <div className="row box-statistics">
                <IssTable height={'calc(100% - 82px)'} pagination={true} data={currentBeaconHistoryTableData} itemsPerPage={9} dropdownDirection={'up'} />
              </div>
            )}
        </div>
      ) : (
        <div className="loading_wrap">
          <Loading isVisible={true} />
        </div>
      )}
      {isAlert && <Alert isOpen={isAlert} title={alertTitle} message={alertMessage} onClose={onCloseAlert} />}
    </div>
  );
}

IssueHistoryBeaconCommon.propTypes = {
  username: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  username: state.currentUser.username,
});

export default connect(mapStateToProps)(IssueHistoryBeaconCommon);
