import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';

//import * as serviceWorker from './serviceWorker';
import configureStore from './store';

import './index.css';

//ISS mock data testing, 해당 코드는 임시 코드며 향후 제거함 (mocks 폴더 포함)
// import { worker } from './mocks/worker';
// if (process.env.NODE_ENV === 'development') {
//   worker.start();
// }

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

//ReactDOM.render(<App />, document.getElementById('root'));
//serviceWorker.unregister();
