import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_BASE_URL } from '../../../../constants';
import { getHeadersFormData } from '../../../../util/actionUtil';
import CommonDialog from '../../../../common/dialog/CommonDialog';
import { connect } from 'react-redux';
import { Button, DialogContent, DialogTitle, Table, TableCell, TableContainer, TableRow, TextField, Checkbox, Select, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ImageEdit from './ImageEdit';

function CarouselEventForm({ categoryIdx, deviceType, country, eventBanner, handleClose, searchList, filterData }) {
  const prefix = `http://kic-${window.location.host === 'channel.lgtvcommon.com' ? '' : 'qt2-'}ngfts.lge.com/fts/gftsDownload.lge?`;
  const [isSave, setIsSave] = useState(false);
  const [eventBannerTitle, setEventBannerTitle] = useState('');
  const [horizontalImage, setHorizontalImage] = useState('');
  const [horizontalImageName, setHorizontalImageName] = useState('');
  const [horizontalImageInfo, setHorizontalImageInfo] = useState({ w: '', h: '' });
  const [verticalImage, setVerticalImage] = useState('');
  const [verticalImageName, setVerticalImageName] = useState('');
  const [verticalImageInfo, setVerticalImageInfo] = useState({ w: '', h: '' });
  const [focusImage, setFocusImage] = useState('');
  const [focusImageName, setFocusImageName] = useState('');
  const [focusImageInfo, setFocusImageInfo] = useState({ w: '', h: '' });
  const [deepLink, setDeepLink] = useState('N');
  const [linkContentType, setLinkContentType] = useState('');
  const [linkContentId, setLinkContentId] = useState('');
  const [linkContentName, setLinkContentName] = useState('');
  const [isSaveDisabled, setSaveDisabled] = useState(false);
  const [isValidContent, setIsValidContent] = useState(false);
  const [contentDeviceType, setContentDeviceType] = useState('tv');
  const [contentCountry, setContentCountry] = useState(false);
  const [contentCategoryIdx, setContentCategoryIdx] = useState(false);

  useEffect(() => {
    if (categoryIdx) {
      setContentCategoryIdx(categoryIdx ? categoryIdx : '');
    }
    if (deviceType) {
      setContentDeviceType(deviceType ? deviceType : '');
    }
    if (country) {
      setContentCountry(country ? country : '');
    }
  }, [categoryIdx, deviceType, country]);

  useEffect(() => {
    if (eventBanner) {
      setEventBannerTitle(eventBanner.title ? eventBanner.title : '');
      setHorizontalImage(eventBanner.horizontalImage ? `${prefix}${eventBanner.horizontalImage}` : '');
      setVerticalImage(eventBanner.verticalImage ? `${prefix}${eventBanner.verticalImage}` : '');
      setFocusImage(eventBanner.focusImage ? `${prefix}${eventBanner.focusImage}` : '');
      setDeepLink(eventBanner.deepLink ? eventBanner.deepLink : 'N');
      if (eventBanner.deepLink && eventBanner.deepLink === 'Y') {
        setIsValidContent(true);
        setLinkContentType(eventBanner.linkContentType ? eventBanner.linkContentType : '');
        setLinkContentId(eventBanner.linkContentId ? eventBanner.linkContentId : '');
      }
    }
  }, [eventBanner]);

  //deepLink
  useEffect(() => {
    if (deepLink && deepLink === 'Y' && !isValidContent) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [deepLink, isValidContent]);

  const handleInputChange = e => {
    setEventBannerTitle(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsSave(true);
  };

  const checkboxInputChange = e => {
    setDeepLink(e.target.checked ? 'Y' : 'N');
    if (!e.target.checked) {
      setLinkContentId('');
      setIsValidContent(false);
    }
  };
  const deepLinkContentHandle = e => {
    setLinkContentId(e.target.value.trim());
    setIsValidContent(false);
  };
  const deepLinkContentTypeHandle = e => {
    setLinkContentType(e.target.value);
    setIsValidContent(false);
  };

  const saveCarouselEvent = () => {
    setIsSave(false);
    if (!eventBanner || !eventBanner.bannerId) {
      if (horizontalImageName === null || horizontalImageName === '') {
        alert('Please select a horizontal image !');
        return;
      }
      if (verticalImageName === null || verticalImageName === '') {
        alert('Please select a vertical image !');
        return;
      }
      if (focusImageName === null || focusImageName === '') {
        alert('Please select a focus image !');
        return;
      }
    }

    setSaveDisabled(true);
    const horizontalImageFile = document.getElementById('horizontalImage_file').files[0]; //undefined if not uploaded
    const verticalImageFile = document.getElementById('verticalImage_file').files[0]; //undefined if not uploaded
    const focusImageFile = document.getElementById('focusImage_file').files[0]; //undefined if not uploaded

    let formData = new FormData();
    formData.append('eventBannerTitle', eventBannerTitle);
    if (horizontalImageFile) formData.append('horizontalImage', horizontalImageFile);
    if (verticalImageFile) formData.append('verticalImage', verticalImageFile);
    if (focusImageFile) formData.append('focusImage', focusImageFile);
    if (deepLink && deepLink === 'Y') {
      formData.append('deepLink', deepLink);
      formData.append('linkContentType', linkContentType);
      formData.append('linkContentId', linkContentId);
    }

    if (eventBanner && eventBanner.bannerId) {
      fetch(`${API_BASE_URL}/carousel/event/${eventBanner.bannerId}`, {
        method: 'PATCH',
        headers: getHeadersFormData(),
        body: formData,
      })
        .then(res => res.json())
        .then(body => {
          if (body.status) {
            if (body.message) {
              alert(body.message);
            } else {
              alert('Some Error Occurred !');
            }
          } else {
            alert('Successfully save !');
            searchList();
            handleClose();
          }
        })
        .catch(() => {})
        .finally(() => {
          setSaveDisabled(false);
        });
    } else {
      fetch(`${API_BASE_URL}/carousel/event`, {
        method: 'POST',
        headers: getHeadersFormData(),
        body: formData,
      })
        .then(res => res.json())
        .then(body => {
          if (body.status) {
            if (body.message) {
              alert(body.message);
            } else {
              alert('Some Error Occurred !');
            }
          } else {
            alert('Successfully save !');
            searchList();
            handleClose();
          }
        })
        .catch(() => {})
        .finally(() => {
          setSaveDisabled(false);
        });
    }
  };

  const contentIdCheckHandle = () => {
    if (linkContentId === null || linkContentId === '') {
      alert('Please enter the deep link ID. !');
      return;
    }
    fetch(`${API_BASE_URL}/carousel/event/check?linkContentType=${linkContentType}&linkContentId=${linkContentId}&deviceType=${contentDeviceType}&country=${contentCountry}&categoryIdx=${contentCategoryIdx}`, {
      method: 'GET',
      headers: getHeadersFormData(),
    })
      .then(res => res.json())
      .then(body => {
        if (body.result) {
          if (body.result === 'PASS') {
            setLinkContentName(body.data.contentName);
            setIsValidContent(true);
          } else {
            setLinkContentName('');
            setIsValidContent(false);
            alert(body.message);
          }
        } else {
          alert(body.message);
        }
      })
      .catch(() => {})
      .finally(() => {
        //setSaveDisabled(false);
      });
  };

  return (
    <Fragment>
      <DialogTitle className="pop_head" id="form-dialog-title">
        EVENT
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={'pop_body'}>
          <div>
            <TableContainer className={'tbl_wrap'}>
              <Table className={'tbl_row_typ1'}>
                <tbody>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Event Title</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <TextField fullWidth name="title" required margin="dense" variant="outlined" value={eventBannerTitle} onChange={event => handleInputChange(event)} placeholder="Title" />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Horizontal Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit
                        image={horizontalImage}
                        setImage={setHorizontalImage}
                        imageName={horizontalImageName}
                        setImageName={setHorizontalImageName}
                        imageInfo={horizontalImageInfo}
                        setImageInfo={setHorizontalImageInfo}
                        inputType="horizontalImage"
                        guideText="Recommended Size: 1280 x 720px"
                        isEdit={true}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Vertical Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit image={verticalImage} setImage={setVerticalImage} imageName={verticalImageName} setImageName={setVerticalImageName} imageInfo={verticalImageInfo} setImageInfo={setVerticalImageInfo} inputType="verticalImage" guideText="Recommended Size: 610 x 968px" isEdit={true} />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className="is_required">Focus Image</label>
                    </TableCell>
                    <TableCell colSpan={5}>
                      <ImageEdit image={focusImage} setImage={setFocusImage} imageName={focusImageName} setImageName={setFocusImageName} imageInfo={focusImageInfo} setImageInfo={setFocusImageInfo} inputType="focusImage" guideText="Recommended Size: 2438 x 1525px" isEdit={true} />
                    </TableCell>
                  </TableRow>
                  <TableRow width="100%" height="80">
                    <TableCell>
                      <label className={deepLink && deepLink === 'Y' ? 'is_required' : ''}>Deep Link</label>
                    </TableCell>
                    <TableCell colSpan={deepLink && deepLink === 'Y' ? 1 : 5}>
                      <Checkbox id="deepLink" name="deepLink" checked={deepLink === 'Y' ? true : false} onChange={checkboxInputChange} />
                    </TableCell>
                    {deepLink && deepLink === 'Y' && (
                      <Fragment>
                        <TableCell colSpan={2}>
                          <Select name="linkContentType" id="linkContentType" style={{ width: 100 }} value={linkContentType} onChange={event => deepLinkContentTypeHandle(event)}>
                            {filterData &&
                              filterData.carouselTypeList
                                .filter(f => f !== 'event' && f !== 'more')
                                .map(n => (
                                  <MenuItem key={n} value={n}>
                                    {n}
                                  </MenuItem>
                                ))}
                          </Select>
                          &nbsp;
                          <TextField style={{ width: 150 }} name="linkContentId" required margin="dense" variant="outlined" value={linkContentId} onChange={event => deepLinkContentHandle(event)} placeholder="Content ID" disabled={!(deepLink && deepLink === 'Y')} />
                        </TableCell>
                        <TableCell colSpan={1}>{deepLink && deepLink === 'Y' && <Fragment>{isValidContent ? <label style={{ color: 'BLUE' }}>Available IDs</label> : <label style={{ color: 'RED' }}>Please check Content ID</label>}</Fragment>}</TableCell>
                        <TableCell colSpan={1}>
                          <Button component="span" className={isValidContent ? 'btn_color4' : 'btn_color2'} style={{ width: 100, marginTop: 0 }} onClick={contentIdCheckHandle}>
                            CHECK ID
                          </Button>
                        </TableCell>
                      </Fragment>
                    )}
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </div>
          <div className={'btn_set_wrap'}>
            <Button color="primary" onClick={handleClose} className={'btn_color1'} disabled={isSaveDisabled}>
              Cancel
            </Button>
            <Button type="submit" color="primary" className={'btn_color2'} disabled={isSaveDisabled}>
              Save
            </Button>
          </div>
        </DialogContent>
      </form>
      <CommonDialog
        open={isSave}
        description="Do you want to register for the event?"
        handleCancel={() => {
          setIsSave(false);
        }}
        handleOk={() => {
          saveCarouselEvent();
        }}
      />
    </Fragment>
  );
}

CarouselEventForm.propTypes = {
  filterData: PropTypes.object.isRequired,
  eventBanner: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  searchList: PropTypes.func.isRequired,
};
export default connect()(CarouselEventForm);
