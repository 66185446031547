import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import TableChartDialog from './TableChartDialog';

function TableChart({ data, dateList, date, date2 }) {
  const [open, setOpen] = React.useState(false);
  const tableData = data.data.map(n => ({ ...n, diff: <span className={n[date] - n[date2] >= 0 ? 'summary_up' : 'summary_down'}>{(Math.round((n[date] - n[date2]) * 100) / 100).toLocaleString()}</span> }));
  return (
    data &&
    data.data.length > 0 &&
    dateList && (
      <div>
        <div className="tbl_wrap tbl_col_typ1 tbl_summary exportable">
          <div className="btn_set_wrap tbl_topL">
            <Button
              variant="contained"
              className={'btn_color3'}
              onClick={() => {
                setOpen(true);
              }}>
              CHART
            </Button>
          </div>
          <MaterialTable
            title=""
            columns={[{ title: '', field: 'item', width: 200 }].concat(dateList.map(n => ({ title: n, field: n, width: 120 }))).concat({ title: [`[${date}]-[${date2}]`], field: 'diff', width: 150 })}
            data={tableData}
            //editable={{}}
            options={{
              fixedColumns: {
                left: 1,
                //right: 1,
              },
              paging: false,
              exportButton: true,
              //toolbar: { exportTitle: 'cdpFile', exportName: 'cdpFile', exportAriaLabel: 'cdpTest' },
              //oubleHorizontalScroll: true,
            }}
          />
          <TableChartDialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>
      </div>
    )
  );
}

TableChart.propTypes = {
  data: PropTypes.object.isRequired,
  dateList: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  date2: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
  data: state.statistic.data,
});

export default connect(mapStateToProps)(TableChart);