import React from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import MaterialTable from 'material-table';
//import { Link } from 'react-router-dom';
//import { getCategoryAction } from '../../actions/homeAppActions';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { request } from '../../util/request';
import { isInteger } from '../../util/util';
import { categoryContentTypes } from './TabCategoryAdd';
import TabDetailSkin from './TabDetailSkin';
import { Switch } from '@material-ui/core';

export function TabCategoryDetail({ status, region, type, tabId, categoryId, history, tabCategory }) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [channelGenreView, setChannelGenreView] = React.useState(false);
  const [content, setContent] = React.useState({});
  //const [langCode, setLangCode] = React.useState('en-US');
  /* langList, editLangList - due to CANCEL button, if cancel button is clicked, original langList should be displayed. to do this, additional variable is necessary.
  if edit-cancel button is not required, editLangList should be deleted  */
  const [allLangList, setAllLangList] = React.useState([]);
  const [langList, setLangList] = React.useState([]);
  const [editLangList, setEditLangList] = React.useState([{ langCode: '', name: '' }]);
  const setEditLang = (i, type, value) => {
    let newData = [...editLangList];
    newData[i][type] = value;
    setEditLangList(newData);
  };
  const skeletonTypeList = ['default', 'carousel', 'live', 'my', 'search', 'slider'];

  const orderArr = tabCategory.filter(n => (type === 'tab' ? n.categoryId === -1 && n.tabId !== tabId * 1 : n.parentId === tabId * 1 && n.categoryId !== categoryId * 1)).map(n => (type === 'tab' ? n.tabOrder : n.categoryOrder));
  const fetchContent = async type => {
    if (type === 'tab') {
      const { tabName, tabOrder, tabMultiLangList, themeApply, bgImg, previewImg, buttonImg, title, description, tabColor, categoryColor, titleColor, plotColor, locationType, categoryFlag, region, deviceType, platformVersion, status, tabImg, opacityImg, skeletonType } = await request(`/api/tab/${tabId}`, 'GET', null, true);
      const cont = { tabName, tabOrder, themeApply, bgImg, previewImg, buttonImg, title, description, tabColor, categoryColor, titleColor, plotColor, locationType, categoryFlag, region, deviceType, platformVersion, status, tabImg, opacityImg, skeletonType };
      setContent(cont);
      setLangList(tabMultiLangList);
      if (tabMultiLangList && tabMultiLangList.length > 0) setEditLangList(tabMultiLangList); // when defaultNList.leng===0, at least one empty editable row should be
    } else {
      const { categoryName, categoryOrder, contentType, mappingType, categoryType, categoryMultiLangList, channelGenreName, genreCode } = await request(`/api/category/${categoryId}`, 'GET', null, true);
      const cont = { categoryName, categoryOrder, contentType, mappingType, categoryType, channelGenreName, genreCode};
      setContent(cont);
      setLangList(categoryMultiLangList);
      if (categoryMultiLangList.length > 0) setEditLangList(categoryMultiLangList);
    }
  };
  const fetchAllLang = async() => {
    const langList = await request(`/api/lang-list`, 'GET', null, true);
    setAllLangList(langList);
  };

  React.useEffect(() => {
    fetchContent(type);
    fetchAllLang();
  }, [type, tabId, categoryId]);

  const name = content && (type === 'tab' ? content.tabName : content.categoryName);
  const locationType = content && (type === 'tab' ? content.locationType : content.locationType);

  const clickSave = async event => {
    event.preventDefault();
    let name = type === 'tab' ? content.tabName : content.categoryName;
    let order = type === 'tab' ? content.tabOrder : content.categoryOrder;

    if (!name) alert('The name is empty ! Please enter the name !');
    //else if (type === 'category' && !content.contentType) alert('The type is empty ! Please select the type !');
    //else if (editLangList.find(n => !n.langCode || !n.name)) alert('Some language data is empty ! Please enter the right data ! If there is no translated title, please click delete button in Multilingual Name !');
    // TODO langList langCode dup error check
    else {
      alert('Are you sure to save this?');
      let requestBody = { ...content };
      if (type === 'tab') {
        const tabImg = document.getElementById('input_tab').files[0];
        const opacityImg = document.getElementById('input_opacity').files[0];
        const bgImg = document.getElementById('input_bg').files[0]; //undefined if not uploaded
        //if (!bgImg) alert('Please select a background image file to update !');
        const previewImg = document.getElementById('input_preview').files[0]; //undefined if not uploaded
        //if (!previewImg) alert('Please select a preview image file to update !');
        const buttonImg = document.getElementById('input_button').files[0];
        requestBody = new FormData();
        requestBody.append('tabName', content.tabName);
        requestBody.append('categoryFlag', content.categoryFlag === 'Y' ? 'Y' : 'N');
        requestBody.append('themeApply', content.themeApply === 'Y' ? 'Y' : 'N');
        if (tabImg) requestBody.append('tabImg', tabImg);
        if (opacityImg) requestBody.append('opacityImg', opacityImg);
        if (bgImg) requestBody.append('bgImg', bgImg);
        if (previewImg) requestBody.append('previewImg', previewImg);
        if (buttonImg) requestBody.append('buttonImg', buttonImg);
        requestBody.append('isTabImgDeleted', content.tabImg === '' ? 'Y' : 'N');
        requestBody.append('isOpacityImgDeleted', content.opacityImg === '' ? 'Y' : 'N');
        requestBody.append('title', content.title !== null ? content.title : '');
        requestBody.append('description', content.description !== null ? content.description : '');
        requestBody.append('locationType', content.locationType);
        requestBody.append('skeletonType', content.skeletonType);
        requestBody.append('tabOrder', content.tabOrder);
        requestBody.append('region', content.region);
        requestBody.append('deviceType', content.deviceType);
        requestBody.append('platformVersion', content.platformVersion);
        requestBody.append('status', content.status);
        if (content.tabColor && content.tabColor.r) requestBody.append('tabColor', `#${content.tabColor.r.toString(16)}${content.tabColor.g.toString(16)}${content.tabColor.b.toString(16)}`);
        if (content.categoryColor && content.categoryColor.r) requestBody.append('categoryColor', '#' + content.categoryColor.r.toString(16) + content.categoryColor.g.toString(16) + content.categoryColor.b.toString(16));
        if (content.titleColor && content.titleColor.r) requestBody.append('titleColor', '#' + content.titleColor.r.toString(16) + content.titleColor.g.toString(16) + content.titleColor.b.toString(16));
        if (content.plotColor && content.plotColor.r) requestBody.append('plotColor', '#' + content.plotColor.r.toString(16) + content.plotColor.g.toString(16) + content.plotColor.b.toString(16));
        //console.log('content???',content);
      }
      let isSuccess = false;
      if (type === 'tab') {
        isSuccess = await request(`/api/tab_withImg/${type === 'tab' ? tabId : categoryId}`, 'PATCH', requestBody, false, false, type === 'tab' ? true : false);
      } else if (type === 'category') {
        if (content.contentType.indexOf('tvshow-series') > -1 && content.mappingType === 'auto') {
          alert('tvshow-series cannot be set auto mapping rule.');
          return;
        } else if ((content.categoryType === 'upcoming' && content.contentType.indexOf('livestream') > -1) ||
                   (content.categoryType === 'upcoming' && content.contentType.indexOf('tvshow-series') > -1)) {
           alert('Upcoming is only available in live and normal VOD types.');
           return;
        } else if ((content.categoryType === 'upcoming' && (content.contentType.indexOf('live') > -1 && content.contentType.indexOf('tvshow') > -1)) ||
                   (content.categoryType === 'upcoming' && (content.contentType.indexOf('live') > -1 && content.contentType.indexOf('movie') > -1))) {
           alert('Upcoming live and VOD cannot be set together.');
           return;
        }
        isSuccess = await request(`/api/category/${categoryId}`, 'PATCH', { ...content, updateFlag: 'Y' });
      }

      if (isSuccess) {
        isSuccess = await request(
          `/api/${type}/${type === 'tab' ? tabId : categoryId}/lang`,
          'PATCH',
          editLangList.filter(n => n.langCode && n.name),
        );
        if (isSuccess) {
          setIsEdit(false);
          fetchContent(type);
          alert('Successfully saved !');
        }
      }
    }
  };

  return (
    <div>
      <TableContainer className={'tbl_wrap'}>
        <Table className={'tbl_row_typ1 tbl_notification'} aria-label="spanning table">
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '35%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <td colSpan="3">
                {isEdit ? (
                  <div>
                    <TextField
                      //fullWidth
                      style={{ width: '300px' }}
                      //margin="dense"
                      variant="outlined"
                      value={name}
                      onChange={e => {
                        let newCat = { ...content };
                        if (type === 'tab') {
                          newCat.tabName = e.target.value;
                        } else {
                          newCat.categoryName = e.target.value;
                        }
                        setContent(newCat); //TODO: why Cannot read property 'value' of null from(e.target)?
                      }}
                      disabled={channelGenreView}
                    />
                  </div>
                ) : (
                  name
                )}
              </td>
            </TableRow>
            <TableRow>
              <TableCell>Multilingual Name</TableCell>
              <td colSpan="3" /*style={{ display: 'flex' }*/>{isEdit ? <MultiLangEdit editLangList={editLangList} setEditLangList={setEditLangList} setEditLang={setEditLang} allLangList={allLangList} /> : (langList || []).map(n => <div key={n.langCode}>{`${n.langCode} | ${n.name}`}</div>)}</td>
            </TableRow>
            {type === 'tab' &&
              <TableRow>
                <TableCell>Location Type</TableCell>
                <td colSpan="3">
                  {isEdit ? (
                    <div>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="locationType"
                          id="locationType"
                          value={locationType}
                          onChange={e => {
                            let newLocationType = { ...content };
                            if (type === 'tab') newLocationType.locationType = e.target.value;
                            setContent(newLocationType); //TODO: why Cannot read property 'value' of null from(e.target)?
                          }}
                          required
                          select>
                          <MenuItem name="locationType" value="top">
                            top
                          </MenuItem>
                          <MenuItem name="locationType" value="scroll">
                            scroll
                          </MenuItem>
                          <MenuItem name="locationType" value="bottom">
                            bottom
                          </MenuItem>
                          <MenuItem name="locationType" value="link">
                            link
                          </MenuItem>
                        </TextField>
                      </TableCell>
                    </div>
                  ) : (
                    locationType
                  )}
                </td>
              </TableRow>
            }
            {type === 'tab' &&
              <TableRow>
                <TableCell>Skeleton Type</TableCell>
                <td colSpan="3">
                  {isEdit ? (
                    <div>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="skeletonType"
                        id="skeletonType"
                        value={content.skeletonType}
                        onChange={e => {
                          setContent({ ...content, ...{ [e.target.name]: e.target.value } });
                        }}
                        required
                        select>
                        {[...skeletonTypeList].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                  </div>
                  ) : (
                    content.skeletonType
                  )}
                </td>
              </TableRow>
            }
            {type === 'tab' &&
              <TableRow>
                <TableCell>Category Flag</TableCell>
                <td colSpan="3">
                  <Switch
                    checked={content.categoryFlag === 'Y'}
                    onChange={() =>
                      isEdit
                        ? setContent({ ...content, categoryFlag: content.categoryFlag === 'Y' ? 'N' : 'Y'})
                        : alert('Click Edit button to change category flag')
                    }
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </td>
              </TableRow>
            }
            {type === 'tab' && <TabDetailSkin isEdit={isEdit} content={content} setContent={setContent} />}
            {content && type === 'category' && <CategoryDetail isEdit={isEdit} content={content} setContent={setContent} region={region} channelGenreView={channelGenreView} setChannelGenreView={setChannelGenreView}/>}
          </TableHead>
        </Table>
      </TableContainer>
      <div className="btn_set_wrap">
        <div className="btn_set_left_cont">
          <Button
            variant="contained"
            className="btn_color3"
            onClick={() => {
              history.push('/HOME-APP/tab');
            }}>
            LIST
          </Button>
        </div>
        {status === 'Saved' && (
          <React.Fragment>
            <Button
              variant="contained"
              className="btn_color2"
              onClick={
                isEdit
                  ? async e => {
                    clickSave(e);
                  }
                  : () => {
                    setIsEdit(true);
                  }
              }>
              {isEdit ? 'save' : 'edit'}
            </Button>
            {/* {isEdit && (
              <Button
                variant="contained"
                className="btn_color1"
                onClick={() => {
                  setIsEdit(false);
                }}>
                cancel
              </Button>
            )} */}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
TabCategoryDetail.propTypes = {};
const mapStateToProps = state => ({
  tabCategory: state.homeApp.tabCategory,
});

export default connect(mapStateToProps)(withRouter(TabCategoryDetail));

export function CategoryDetail({ isEdit, content, setContent, region, channelGenreView, setChannelGenreView }) {
  const [genres, setGenres] = React.useState([]);

  const requestResult = async () => {
    const channelGenreResult = await request(`/api/category/channel-genre/${region}`, 'GET', null, true);
    setGenres(channelGenreResult);
  };

  React.useEffect(() => {
    if (region) {
      requestResult();
    }
  }, [region]);

  React.useEffect(() => {
    if (content && content.categoryType === 'channel_genre') {
      setChannelGenreView(true);
    } else {
      setChannelGenreView(false);
    }
  }, [content]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>Category Type</TableCell>
        <td colSpan="3">
          {isEdit ? (
            <FormControl variant="outlined">
              <Select
                value={content.categoryType}
                onChange={e => {
                  if (e.target.value === 'channel_genre') {
                    setChannelGenreView(true);
                  } else {
                    setChannelGenreView(false);
                  }
                  setContent(() => {
                    let newCat = { ...content };
                    newCat.categoryType = e.target.value;
                    return newCat;
                  });
                }}>
                {['common', 'top', 'rank', 'recent', 'favorite', 'channel_genre', 'genre', 'livestream', 'upcoming'].map(n => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            content.categoryType
          )}
        </td>
      </TableRow>
      <TableRow></TableRow>
      {
        (channelGenreView || content.categoryType === 'channel_genre')
        ?
        (
        <TableRow>
          <TableCell>Channel Genre</TableCell>
          <td colSpan="3">
            {isEdit ? (
              <FormControl variant="outlined">
                <Select
                  value={content.categoryName}
                  onChange={e => {
                    setContent(() => {
                      let newCat = { ...content };
                      newCat.channelGenreName = e.target.value;
                      newCat.categoryName = e.target.value;
                      newCat.genreCode = genres.find(n => n.genreName === e.target.value).genreCode;
                      return newCat;
                    });
                  }}>
                  {genres.map(n => (
                    <MenuItem key={n.genreCode} value={n.genreName}>
                      {n.genreText}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              content.categoryName+'('+content.genreCode+')'
            )}
          </td>
        </TableRow>
        )
        :
        (
        <TableRow></TableRow>
        )
      }
      <TableRow>
        <TableCell> Content Type</TableCell>
        <td>
          {isEdit ? (
            <FormControl variant="outlined">
              <Select
                multiple
                value={content.contentType.split(',')}
                onChange={e => {
                  let newCat = Object.assign({}, content);
                  newCat.contentType = e.target.value.filter(n => n).join(',');
                  setContent(newCat);
                }}>
                {categoryContentTypes.map(n => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            content.contentType
          )}
        </td>
        <TableCell>Mapping Type</TableCell>
        <td>
          {isEdit ? (
            <FormControl variant="outlined">
              <Select
                value={content.mappingType}
                onChange={e => {
                  setContent(() => {
                    let newCat = { ...content };
                    newCat.mappingType = e.target.value;
                    return newCat;
                  });
                }}>
                {['manual', 'auto', 'slider', 'carousel'].map(n => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            content.mappingType
          )}
        </td>
      </TableRow>
    </React.Fragment>
  );
}

export function MultiLangEdit({ editLangList, setEditLangList, setEditLang, allLangList }) {
  //const [editLangList, setEditLangList] = React.useState(langList.length > 0 ? langList : [{ langCode: '', name: '' }]);
  return editLangList.map((n, i) => (
    <div key={`${n.langCode}${i}`} style={{ marginBottom: '5px' }}>
      <div className="edit_mulyi_lang_row" style={{ display: 'flex' }}>
        <FormControl variant="outlined" style={{ width: '120px', marginRight: '15px', marginTop: '8px' }}>
          <InputLabel>Language</InputLabel>
          <Select
            value={n.langCode}
            onChange={e => {
              setEditLang(i, 'langCode', e.target.value);
            }}>
            {allLangList.map(n => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          //fullWidth
          margin="dense"
          variant="outlined"
          value={n.name}
          style={{ width: '300px', marginRight: '15px' }}
          onChange={e => {
            setEditLang(i, 'name', e.target.value);
          }}
        />
        <Button
          variant="contained"
          className="btn_color3"
          style={{
            maxHeight: '37px',
            marginTop: '7px',
          }}
          onClick={() => {
            setEditLangList(editLangList.filter((n2, i2) => i2 !== i));
          }}>
          delete
        </Button>
        <Button
          variant="contained"
          className="btn_color3"
          style={{
            maxHeight: '37px',
            marginTop: '7px',
          }}
          onClick={() => {
            setEditLangList(editLangList.concat({ langCode: '', name: '' }));
          }}>
          add
        </Button>
      </div>
    </div>
  ));
}
