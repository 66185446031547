import {
  SET_CHANNEL_MAPPING,
  SET_CHANNEL_MAPPING_FETCHING,
  SET_SOURCE_CHANNEL,
  SET_CONF_MAP,
  SET_HIST_CONF_MAP,
  SET_CONFIG_LIST,
  SET_SAVE_FLAG,
  SET_PRECHECK,
  SET_EXPORTS,
  SET_API_FAIL,
  SET_SAVE_FAIL,
  SET_VIEW_DATA,
  MERGE_CONFIG_LIST,
  SET_ORIGINAL,
  MERGE_SOURCE_LIST,
  SET_PUBLISH_RESULT,
  SET_ZIP_DMA_CODE_RESULT,
  SET_DMA_GROUP_CODE_RESULT,
  SET_REVOKE_CHANNEL_NAME_LIST,
  SET_PUBLISH_CHANNEL_NAME_RESULT,
  SET_CHANNELMAP_PUBLISH_DATA,
  SET_CHANNELMAP_PUBLISH_FAIL,
  SET_CHANNELMAP_DATA,
  SET_EDIT_MEDIA_STATIC_URL,
  SET_EDIT_MEDIA_STATIC_URL_FAIL,
  SET_CHANNEL_MAPPING_RESERVATION,
  SET_CHANNEL_MAPPING_RESERVATION_BY_ID_TYPE,
  SET_CHANNEL_MAPPING_HIDDEN_DATA,
  GET_RS_CHANNEL_IDS,
} from './actionTypes';
import { showLoader, hideLoader } from './loaderActions';
import { API_BASE_URL, API_URL } from '../constants';
//import xmlParser from 'react-xml-parser';
import { getHeaders } from '../util/actionUtil';
import xlsx from 'json-as-xlsx';
import data from '../reducers/statistic/data';

const URL = API_URL;
const APIURL = API_BASE_URL;
const setShowColumnOnOFFData = showObOffData => ({
  type: SET_CHANNEL_MAPPING_HIDDEN_DATA,
  payload: showObOffData,
});
const setChannelMappingFetching = value => ({
  type: SET_CHANNEL_MAPPING_FETCHING,
  payload: value,
});
const setConfigList = body => ({
  type: SET_CONFIG_LIST,
  payload: body,
});
const setOriginalConfig = body => ({
  type: SET_ORIGINAL,
  payload: body,
});
const mergeConfig = body => ({
  type: MERGE_CONFIG_LIST,
  payload: body,
});
const mergeChannelSource = body => ({
  type: MERGE_SOURCE_LIST,
  payload: body,
});
const doFail = body => ({
  type: SET_API_FAIL,
  payload: body,
});
const setViewData = viewData => ({
  type: SET_VIEW_DATA,
  payload: viewData,
});
const setPbResult = viewData => ({
  type: SET_PUBLISH_RESULT,
  payload: viewData,
});
const setPbChannelNameResult = viewData => ({
  type: SET_PUBLISH_CHANNEL_NAME_RESULT,
  payload: viewData,
});
const setDmaCodedata = data => ({
  type: SET_ZIP_DMA_CODE_RESULT,
  payload: data,
});
const setDmaGroupCodedata = data => ({
  type: SET_DMA_GROUP_CODE_RESULT,
  payload: data,
});

export const setShowColumnOnOFFTableData = list => dispatch => {
  if (list.length < 0)
    dispatch({
      type: SET_CHANNEL_MAPPING_HIDDEN_DATA,
    });
  else {
    dispatch(setShowColumnOnOFFData(list));
  }
};
export const getFeedInfoList = (system, cntry, cp) => dispatch => {
  if (cp === 'ALL') cp = '';
  let apiUrl = `rssfeed/${cntry}/feeds?cpName=${cp}`;
  dispatch(setChannelMappingFetching(true));
  fetch(`${URL}/sdpapi/${apiUrl}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body =>
      dispatch({
        type: SET_CHANNEL_MAPPING,
        payload: body,
      }),
    )
    .catch(() => dispatch(doFail()));
};

export const getRsChannelIds = (country, channelIds) => dispatch => {
  let apiUrl = `${APIURL}/admin/${country}/channel-ids`;
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(channelIds),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'GET_RS_CHANNEL_IDS', payload: { list: body, isDataLoad: true } });
    })
    .catch(ex => {
      dispatch({ type: 'GET_RS_CHANNEL_IDS_ERROR', payload: ex });
    });
};

export const getRsChannelIdsForAddButton = (country, channelIds) => dispatch => {
  let apiUrl = `${APIURL}/admin/${country}/channel-ids`;
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(channelIds),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'GET_ADD_RS_CHANNEL_IDS', payload: { list: body, isDataLoad: true } });
    })
    .catch(ex => {
      dispatch({ type: 'GET_RS_CHANNEL_IDS_ERROR', payload: ex });
    });
};

export const getSourceChannelList = (system, feeds, rowData) => dispatch => {
  dispatch(setChannelMappingFetching(true));
  let config = {
    method: 'GET',
    headers: getHeaders(),
  };
  let apiUrl = `${APIURL}/admin/providers/${rowData.cpName}/country/${rowData.cntryCode}/channels`;
  dispatch(showLoader());
  dispatch(setChannelMappingFetching(true));
  fetch(apiUrl, config)
    .then(res => res.json())
    .then(body => {
      dispatch({ type: 'GET_RS_CHANNEL_IDS', payload: { list: [], isDataLoad: false } });
      dispatch(hideLoader());

      if (body.status) alert(`[Server Response Error]${body}`);
      else {
        let list = body.list;

        let sourceChannel = {
          channellist: [...list],
        };

        const channelIds = list.map(id => id.channelId) || [];

        dispatch(getRsChannelIds(rowData.cntryCode, channelIds));
        dispatch({
          type: SET_SOURCE_CHANNEL,
          payload: sourceChannel,
        });
      }
    })
    .catch(ex => {
      alert(ex);
      dispatch(doFail());
      dispatch(hideLoader());
    });
};

export const setChannelMapIdList = (system, cntry, ric) => dispatch => {
  let apiUrl = `${URL}/sdpapi/channelmaps-cdp/${cntry}`;
  dispatch(setChannelMappingFetching(true));
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      const mapList = body.map(v => {
        return {
          channelMapId: v.channelMapId,
        };
      });
      dispatch({
        type: SET_CONF_MAP,
        payload: {
          type: 'api',
          mapList: mapList,
        },
      });
    })
    .catch(() => dispatch(doFail()));
};

export const setHistoryChannelMapIdList = (system, cntry, ric) => dispatch => {
  let apiUrl = `${URL}/sdpapi/channelmaps-cdp/${cntry}`;
  dispatch(setChannelMappingFetching(true));
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      const mapList = body.map(v => {
        return {
          channelMapId: v.channelMapId,
        };
      });
      dispatch({
        type: SET_HIST_CONF_MAP,
        payload: {
          type: 'api',
          mapList: mapList,
        },
      });
    })
    .catch(() => dispatch(doFail()));
};

export const settingViewData = viewData => dispatch => {
  dispatch(setViewData(viewData));
};
// collect -> FTP 수행
export const setCollect = (feeds, cntry, cp, callBack) => dispatch => {
  let apiUrl = `${URL}/sdpapi/sdp-feed/collect?cntry=${cntry}&cp=${cp}`;
  dispatch(showLoader());
  dispatch(setChannelMappingFetching(true));
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(hideLoader());
      if (body.fileName) {
        let newArray = [...feeds];
        newArray.forEach(v => {
          if (v.cpName === cp) v['rcvFeedFileName'] = body.fileName;
        });
        dispatch({
          type: SET_CHANNEL_MAPPING, //SET_CHANNEL_COLLECT,
          payload: newArray,
        }); //display received FileName
      }
      callBack(body.fileName);
    })
    .catch(() => {
      dispatch(hideLoader());
      callBack(false);
      dispatch(doFail());
    });
};

export const setChannelConf = (system, channelMapId, cntry, ric, saveType) => dispatch => {
  let apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/saved-channels?saveType=${saveType}`;
  dispatch(setChannelMappingFetching(true));
  dispatch({
    type: SET_SAVE_FLAG,
    payload: true,
  });
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      let newList = body.list.map(v => {
        return {
          channelId: v.channelId,
          channelName: v.channelName,
          channelNumber: v.channelNumber,
          channelGenreName: v.channelGenreName,
          cpName: v.cpName,
          deleteFlag: 'N',
          logoUrl: v.logoUrl,
          change: false,
          dmaGroupCode: v.dmaGroupCode,
          modifier: v.modifier,
          channelStatus: v.channelStatus,
          lastChgDate: v.lastChgDate,
          mediaStaticUrl: v.mediaStaticUrl,
        };
      });
      newList.sort((a, b) => a.channelNumber - b.channelNumber);
      let original = JSON.parse(JSON.stringify(newList));
      dispatch(setConfigList(newList));
      dispatch(setOriginalConfig(original));
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(doFail());
      dispatch(hideLoader());
    });
};

export const mergeSourceList = list => dispatch => {
  dispatch(mergeChannelSource(list));
};

export const mergeChannelConf = list => dispatch => {
  dispatch(mergeConfig(list));
};
export const setChannelMapData = data => dispatch => {
  dispatch({
    type: SET_CHANNELMAP_DATA,
    payload: data,
  });
};

export const setPrecheckList = (system, ric, cntry, channelMapId, publishType) => dispatch => {
  dispatch(setChannelMappingFetching(true));

  fetch(`${URL}/sdpapi/channelmap-cdp/preview/${channelMapId}?isTesting=${publishType === 'testing' ? 'y' : 'n'}`, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      let result = { beforeNAfter: body };
      let previewFinal = {};
      previewFinal[channelMapId] = body.confList.filter(n => n.dChanNo).map(({ dChanNo: chanNum }) => parseInt(chanNum));
      result.preview = previewFinal;

      dispatch({
        type: SET_PRECHECK,
        payload: result,
      });
    })
    .catch(() => dispatch(doFail()));
};

export const doSaveConf = (system, ric, cntry, channelMapId, list, saveType) => dispatch => {
  let apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/saved-channels?saveType=${saveType}`;
  dispatch(setChannelMappingFetching(true));
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(list, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        dispatch({
          type: SET_SAVE_FLAG,
          payload: true,
        });
        dispatch(setChannelConf(system, channelMapId, cntry, ric, saveType));
      } else if (!body.returnValue) {
        dispatch({
          type: SET_SAVE_FAIL,
          payload: `Error: ${body.message}`,
        });
        // dispatch({
        //   type: SET_SAVE_FLAG,
        //   payload: setExportFalse,
        // });
      }
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch({
        type: SET_SAVE_FLAG,
        payload: setExportFalse,
      });
      dispatch(hideLoader());
    });
};

export const getDmaGroupCodeList = country => dispatch => {
  let apiUrl = `${URL}/api/admin/dma-group-codes/${country}`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch(setDmaGroupCodedata(body));
    });
};

export const setPublish = (system, ric, cntry, channelMapId, publishType, channelMapSync) => dispatch => {
  let apiUrl = '';
  if (system === 'sdp') {
    apiUrl = `${URL}/sdpapi/channelmap/publish-channels?ric=${ric}&cntry=${cntry}&id=${channelMapId}`;
  } else {
    if (publishType === 'publish') {
      apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/${channelMapSync}/published-channels`;
    } else {
      apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/test-channels`;
    }
  }
  dispatch(setChannelMappingFetching(true));
  fetch(apiUrl, {
    method: system === 'sdp' ? 'PATCH' : 'POST',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({
        type: SET_EXPORTS,
        payload: { result: body.returnValue, msg: body },
      });
      if (!body.returnValue) {
        dispatch(setPbResult(true));
      }
    })
    .catch(() => {
      dispatch(setPbResult(true));
      dispatch(doFail());
    });
};
export const setExportFalse = bool => dispatch => {
  dispatch({
    type: SET_EXPORTS,
    payload: { result: bool, msg: '' },
  });
};

export const getChannelMapInfo = () => dispatch => {
  let config = {
    method: 'GET',
    headers: getHeaders(),
  };
  let apiUrl = `${APIURL}/admin/channel-map-info`;
  dispatch(showLoader());
  fetch(apiUrl, config)
    .then(res => res.json())
    .then(body => {
      dispatch(hideLoader());
      if (body.status) alert(`[Server Response Error]${body}`);
      else {
        let data = [
          {
            sheet: 'channel_map_info',
            columns: [
              { label: 'Date', value: row => row.date },
              { label: 'Country', value: row => row.country },
              { label: 'Channel Map', value: row => row.chMap },
              { label: 'Ch. No', value: row => row.chNo },
              { label: 'Ch. ID', value: row => row.chId },
              { label: 'Ch. Name', value: row => row.chName },
              { label: 'CP Name', value: row => row.cpName },
              { label: 'Genre', value: row => row.genre },
              { label: 'Status', value: row => row.status },
              { label: 'Last Update', value: row => row.lastUpdated },
              { label: 'Modifier', value: row => row.name },
              { label: 'DMA Group Name', value: row => row.dmaGroupName },
            ],
            content: [...body.list],
          },
        ];

        let xLSettings = {
          fileName: 'All_channel_map',
        };

        xlsx(data, xLSettings);
      }
    })
    .catch(ex => {
      alert(ex);
      dispatch(doFail());
      dispatch(hideLoader());
    });
};

export const getChannelMapInfoData = async (channelMapId, donwloadType, system, startDate, endDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType) => {
  channelName = encodeURIComponent(channelName);
  let apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/history?type=${donwloadType}&system=${system}&selcDate=${startDate}&endDate=${endDate}&pointOfTime=${pointOfTime}&cntry=${cntry}&changeOnly=${changeOnly}&cp=${cp}&channelName=${channelName}&mode=${modeType}`;
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  }).then(res => res.json());
  return response;
};

export const getChannelMapInfoLevelTwoData = async (channelMapId, donwloadType, system, startDate, endDate, ric, cntry, pointOfTime, changeOnly, cp, channelName, modeType, paramOne, paramTwo, paramThree, paramFour) => {
  paramOne = encodeURIComponent(paramOne);
  paramTwo = encodeURIComponent(paramTwo);
  paramThree = encodeURIComponent(paramThree);
  paramFour = encodeURIComponent(paramFour);
  channelName = encodeURIComponent(channelName);
  let apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/history/levelTwo?type=${donwloadType}&system=${system}&selcDate=${startDate}&endDate=${endDate}&pointOfTime=${pointOfTime}&cntry=${cntry}&changeOnly=${changeOnly}&cp=${cp}&channelName=${channelName}&mode=${modeType}&paramOne=${paramOne}&paramTwo=${paramTwo}&paramThree=${paramThree}&paramFour=${paramFour}`;
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  }).then(res => res.json());
  return response;
};

export const retrieveCpList = async cntry => {
  let apiUrl = `${APIURL}/admin/cp/${cntry}`;
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  }).then(res => res.json());
  return response;
};

export const getChannelMapInfoDataHistory = async (channelMapId, donwloadType, system, startDate, endDate, ric, cntry, pointOfTime, changeOnly, cp, channelName) => {
  channelName = encodeURIComponent(channelName);
  let config = {
    method: 'GET',
    headers: getHeaders(),
  };
  let apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/history?type=${donwloadType}&system=${system}&selcDate=${startDate}&endDate=${endDate}&pointOfTime=${pointOfTime}&cntry=${cntry}&changeOnly=${changeOnly}&cp=${cp}&channelName=${channelName}`;
  const response = await fetch(apiUrl, config)
    .then(res => res.json())
    .then(body => {
      if (body.status) alert(`[Server Response Error]${body}`);
      else {
        // country code, cp name, channel id, channel name,
        // genre name, genre code, channel number, paid channel(Y or Blank)
        let data = [
          {
            sheet: 'channel_map_info',
            columns: [
              { label: 'Last Update', value: row => row.lastUpdated },
              { label: 'Country', value: row => row.country },
              { label: 'Channel Map', value: row => row.chMap },
              { label: 'Ch. No', value: row => row.chNo },
              { label: 'Ch. ID', value: row => row.chId },
              { label: 'Ch. Name', value: row => row.chName },
              { label: 'CP Name', value: row => row.cpName },
              { label: 'Genre', value: row => row.genre },
              { label: 'Status', value: row => row.status },
              { label: 'Modifier', value: row => row.name },
              { label: 'DMA Group Name', value: row => row.dmaGroupName },
              { label: 'Type', value: row => row.type },
            ],
            content: [...body.list],
          },
        ];

        let xLSettings = {
          fileName: `${cntry}_${channelMapId}_${donwloadType}_channel_map_history`,
        };

        xlsx(data, xLSettings);
      }
    })
    .catch(ex => {
      alert(ex);
    });
  return response;
};

export const getChannelMapInfoByChMapId = channelMapId => dispatch => {
  let config = {
    method: 'GET',
    headers: getHeaders(),
  };
  let apiUrl = `${APIURL}/admin/channel-map-info/channelmap/${channelMapId}`;
  dispatch(showLoader());
  fetch(apiUrl, config)
    .then(res => res.json())
    .then(body => {
      dispatch(hideLoader());
      if (body.status) alert(`[Server Response Error]${body}`);
      else {
        // country code, cp name, channel id, channel name,
        // genre name, genre code, channel number, paid channel(Y or Blank)
        let data = [
          {
            sheet: 'channel_map_info',
            columns: [
              { label: 'Date', value: row => row.date },
              { label: 'Country', value: row => row.country },
              { label: 'Channel Map', value: row => row.chMap },
              { label: 'Ch. No', value: row => row.chNo },
              { label: 'Ch. ID', value: row => row.chId },
              { label: 'Ch. Name', value: row => row.chName },
              { label: 'CP Name', value: row => row.cpName },
              { label: 'Genre', value: row => row.genre },
              { label: 'Status', value: row => row.status },
              { label: 'Last Update', value: row => row.lastUpdated },
              { label: 'Modifier', value: row => row.name },
              { label: 'DMA Group Name', value: row => row.dmaGroupName },
            ],
            content: [...body.list],
          },
        ];

        let xLSettings = {
          fileName: `${channelMapId}_channel_map`,
        };

        xlsx(data, xLSettings);
      }
    })
    .catch(ex => {
      alert(ex);
      dispatch(doFail());
      dispatch(hideLoader());
    });
};

export const getFeedDataByCountry = cntry => dispatch => {
  let config = {
    method: 'GET',
    headers: getHeaders(),
  };
  let apiUrl = `${APIURL}/admin/country/${cntry}/channels`;
  dispatch(showLoader());
  fetch(apiUrl, config)
    .then(res => res.json())
    .then(body => {
      dispatch(hideLoader());
      if (body.status) alert(`[Server Response Error]${body}`);
      else {
        // country code, cp name, channel id, channel name,
        // genre name, genre code, channel number, paid channel(Y or Blank)
        let data = [
          {
            sheet: `${cntry}_channels`,
            columns: [
              { label: 'Country Code', value: () => cntry },
              { label: 'CP Name', value: row => row.cpName },
              { label: 'Channel Number', value: row => row.channelNumber },
              { label: 'Channel Id', value: row => row.channelId },
              { label: 'Channel Name', value: row => row.channelName },
              { label: 'Genre name', value: row => row.channelGenreName },
              { label: 'Genre code', value: row => row.channelGenreCode },
            ],
            content: [...body.list],
          },
        ];

        let xLSettings = {
          fileName: `${cntry}_feed_channels_list`,
        };

        xlsx(data, xLSettings);
      }
    })
    .catch(ex => {
      alert(ex);
      dispatch(doFail());
      dispatch(hideLoader());
    });
};

export const doSaveChannelName = (system, ric, cntry, list, feeds, rowData) => dispatch => {
  let apiUrl = `${APIURL}/admin/country/${cntry}/editChannelsName`;
  //dispatch(setChannelNameFetching(true));
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(list, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (!body.status) {
        dispatch(getSourceChannelList(system, feeds, rowData));
      } else if (body.status) {
        dispatch({
          type: SET_SAVE_FAIL,
          payload: `Error: ${body.message}`,
        });
      }
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch({
        type: SET_SAVE_FLAG,
        payload: setExportFalse,
      });
      dispatch(hideLoader());
    });
};

export const doRevokeChannelName = (system, ric, cntry, list, feeds, rowData, channellist) => dispatch => {
  let apiUrl = `${APIURL}/admin/country/${cntry}/revokeEditedChannelsName`;
  //dispatch(setChannelNameFetching(true));
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(list, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (!body.status) {
        dispatch({
          type: SET_REVOKE_CHANNEL_NAME_LIST,
          payload: body,
        });
        //dispatch(getSourceChannelList(system, feeds, rowData));
      } else if (body.status) {
        alert(`Error: ${body.message}`);
      }
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch({
        type: SET_SAVE_FLAG,
        payload: setExportFalse,
      });
      dispatch(hideLoader());
    });
};
export const setPublishChannelName = async cntry => {
  const response = await fetch(`${URL}/api/admin/country/${cntry}/editedChannel/preview`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(res => res.json());
  return response;
};
export const setPublishOnConfirm = (system, ric, cntry, channelMapId, saveType, feeds, cpChannelDataViewRow, diffChannel) => dispatch => {
  let url = `${URL}/api/admin/country/${cntry}/editedChannel/publish`;
  dispatch(showLoader());
  fetch(url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(diffChannel, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      dispatch({
        type: SET_CHANNELMAP_PUBLISH_DATA,
        payload: { result: body.returnValue, msg: 'Published Successfully.' },
      });
      if (channelMapId && saveType) {
        dispatch(setChannelConf(system, channelMapId, cntry, ric, saveType));
      }
      dispatch(getSourceChannelList(system, feeds, cpChannelDataViewRow));
      if (!body.returnValue) {
        // Error
        dispatch({
          type: SET_CHANNELMAP_PUBLISH_FAIL,
          payload: { result: true, msg: `Error: ${body.message}` },
        });
      }
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(setPbChannelNameResult(true));
      dispatch(doFail());
    })
    .finally(() => {
      dispatch(hideLoader());
    });
};

export const updateMediaSaticUrl = (system, ric, feeds, data, cpChannelDataViewRow) => dispatch => {
  dispatch(showLoader());
  return fetch(`${URL}/api/admin/updateChannelMediaUrl/ric/${ric}/channelId/${data.channelId}/mediaStaticUrl`, {
    method: 'PUT',
    headers: getHeaders(),
    body: data.mediaStaticUrl,
  })
    .then(res => res.json())
    .then(body => {
      dispatch({
        type: SET_EDIT_MEDIA_STATIC_URL,
        payload: { result: body.returnValue, msg: 'Updated Successfully.' },
      });
      dispatch(getSourceChannelList(system, feeds, cpChannelDataViewRow));
      if (!body.returnValue) {
        // Error
        dispatch({
          type: SET_EDIT_MEDIA_STATIC_URL_FAIL,
          payload: { result: true, msg: `Error: ${body.message}` },
        });
      }
    })
    .catch(() => {
      dispatch(setPbChannelNameResult(true));
      dispatch(doFail());
    })
    .finally(() => {
      dispatch(hideLoader());
    });
};

//Save the channel map Reservation

export const doReservationConf = (system, ric, cntry, channelMapId, list, saveType, reserveDateTime, reserveId) => dispatch => {
  let apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/${reserveDateTime}/reserved-channels?status=${saveType}&reserveId=${reserveId}&countryCode=${cntry}`;
  dispatch(setChannelMappingFetching(true));
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(list, null, ' '),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        reserveId = reserveId == '' ? body.message : reserveId;
        dispatch({
          type: SET_SAVE_FLAG,
          payload: true,
        });
        dispatch(getChannelMapReservationByChannelMapIdAndType(channelMapId, saveType));
        alert('Reservation successfull!');
      } else if (!body.returnValue) {
        dispatch({
          type: SET_SAVE_FAIL,
          payload: `Error: ${body.message}`,
        });
        // dispatch({
        //   type: SET_SAVE_FLAG,
        //   payload: setExportFalse,
        // });
      }
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch({
        type: SET_SAVE_FLAG,
        payload: setExportFalse,
      });
      dispatch(hideLoader());
    });
};

export const getReservationConf = (system, channelMapId, cntry, ric, saveType, reservationId) => dispatch => {
  let apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/reserved-channels/${reservationId}?status=${saveType}`;
  dispatch(setChannelMappingFetching(true));
  dispatch({
    type: SET_SAVE_FLAG,
    payload: true,
  });
  dispatch(showLoader());
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      let newList = (newList = body.list.map(v => {
        return {
          channelId: v.channelId,
          channelName: v.channelName,
          channelNumber: v.channelNumber,
          channelGenreName: v.channelGenreName,
          cpName: v.cpName,
          deleteFlag: 'N',
          logoUrl: v.logoUrl,
          change: false,
          dmaGroupCode: v.dmaGroupCode,
          modifier: v.modifier,
          channelStatus: v.channelStatus,
          action: v.action,
        };
      }));
      newList.sort((a, b) => a.channelNumber - b.channelNumber);
      let original = JSON.parse(JSON.stringify(newList));
      dispatch(setConfigList(newList));
      dispatch(setOriginalConfig(original));
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch(doFail());
      dispatch(hideLoader());
    });
};

export const getChannelMapReservationByChannelMapIdAndType = (channelMapId, saveType) => dispatch => {
  let apiUrl = `${APIURL}/admin/channelmap/${channelMapId}/reserved?status=${saveType}`;
  dispatch(setChannelMappingFetching(true));
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      const reservedList = body.list.map(v => {
        return {
          idx: v.idx,
          channelMapId: v.channelMapId,
          reservationDate: v.reservationDate,
          reservationTime: v.reservationTime,
          reservationStatus: v.reservationStatus,
          saveType: v.status,
          reservationDateTime: v.reservationDateTime,
          useFlag: v.useFlag,
        };
      });
      dispatch(setConfigList([]));
      dispatch({
        type: SET_CHANNEL_MAPPING_RESERVATION_BY_ID_TYPE,
        payload: {
          reservedList: reservedList,
        },
      });
    })
    .catch(() => dispatch(doFail()));
};

export const getAllChannelMapReservation = (cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus) => dispatch => {
  let apiUrl = `${APIURL}/admin/channelmap/reserved?cntry=${cntry}&channelMapId=${hchannelMapId}&type=${hdonwloadType}&startDate=${hstartDate}&endDate=${hendDate}&reserveStatus=${reserveStatus}`;
  dispatch(setChannelMappingFetching(true));
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      const reservedList = body.list.map(v => {
        return {
          idx: v.idx,
          channelMapId: v.channelMapId,
          reservationDate: v.reservationDate,
          reservationTime: v.reservationTime,
          reservationStatus: v.reservationStatus,
          saveType: v.status,
          reservationDateTime: v.reservationDateTime,
          useFlag: v.useFlag,
          reservedChannelList: v.reservedChannelList,
          modifier: v.modifier,
          crtDate: v.crtDate,
        };
      });
      dispatch({
        type: SET_CHANNEL_MAPPING_RESERVATION,
        payload: {
          reservedList: reservedList,
        },
      });
    })
    .catch(() => dispatch(doFail()));
};

export const clearChannelMapReservation = (reservedId, chMap, saveType, publishStatus, channelMapId, cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus) => dispatch => {
  let apiUrl = `${APIURL}/admin/channelmap/reserved/${reservedId}/clear?channelMapId=${channelMapId}&saveType=${saveType}`;
  fetch(apiUrl, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(res => res.json())
    .then(body => {
      if (body.returnValue) {
        dispatch(getAllChannelMapReservation(cntry, hchannelMapId, hdonwloadType, hstartDate, hendDate, reserveStatus));
        if (publishStatus == saveType && channelMapId == chMap) {
          dispatch(getChannelMapReservationByChannelMapIdAndType(chMap, saveType));
        }
      } else if (!body.returnValue) {
        dispatch({
          type: SET_SAVE_FAIL,
          payload: `Error: ${body.message}`,
        });
      }
      dispatch(hideLoader());
    })
    .catch(() => {
      dispatch({
        type: SET_SAVE_FAIL,
        payload: setExportFalse,
      });
      dispatch(hideLoader());
    });
};

// const xmlParsing = str => {
//   let xml = new xmlParser().parseFromString(str);
//   let prg = xml.getElementsByTagName('prgSvc');
//   let prgList = [];
//   let genres = null;
//   prg.forEach(v => {
//     let obj = {
//       channelId: v.attributes.prgSvcId,
//       channelName: '',
//       channelNumber: '',
//       logoUrl: '',
//       paidFlag: '',
//       channelGenreName: '',
//       deleteFlag: 'N',
//       change: false,
//       selection: false,
//     };
//     v.children.forEach(item => {
//       switch (item.name) {
//         case 'name':
//           obj['channelName'] = item.value;
//           break;
//         case 'num':
//           obj['channelNumber'] = item.value;
//           break;
//         case 'images':
//           obj['logoUrl'] = item.children[0].children[0].value;
//           break;
//         case 'xumo:dmost':
//           genres = item.children[0].children;
//           genres.forEach(v => {
//             if (v.name === 'xumo:categories') {
//               v.children.forEach(val => {
//                 if (val.attributes.categoryId) obj['channelGenreName'] = val.value;
//               });
//             }
//             if (v.name === 'xumo:flags') {
//               v.children.forEach(val => {
//                 if (val.name === 'xumo:paid') {
//                   obj['paidFlag'] = val.value === 0 ? 'N' : 'Y';
//                 }
//               });
//             }
//           });
//           break;
//         default:
//           break;
//       }
//     });
//     prgList.push(obj);
//   });
//   return [...prgList];
// };
// const jsonParsing = str => {
//   if (!str) {
//     return [];
//   }
//   let parseJSON = JSON.parse(str);
//   let list = [];
//   parseJSON.channelList.forEach(v => {
//     let obj = {
//       channelId: v.channelId,
//       channelName: v.channelName,
//       channelNumber: v.chNo,
//       logoUrl: v.imgPath,
//       paidFlag: v.payFlag,
//       channelGenreName: v.channelGroupname,
//       deleteFlag: 'N',
//       change: false,
//       selection: false,
//     };
//     list.push(obj);
//   });
//   return list;
// };
