import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { Dialog, DialogActions, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { setPublishChannelName, setPublishOnConfirm } from '../../../actions/channelMappingActions';

function PublishEditedChannel({ system, ric, onClose, cntry, setPublishOnConfirm, pbResult, publishChannelName, channelMappedData, feeds, cpChannelDataViewRow }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [onPublish, setOnPublish] = React.useState(false);
  const [editedChannel, setEditedChannel] = React.useState([]);
  const [publishedData, setPublishedData] = React.useState(false);
  React.useEffect(() => {
    setPublishChannelName(cntry).then((response) => {
      setEditedChannel(response.editedChannelList)
    })
  }, [cntry]);

  React.useEffect(() => {
    if (publishChannelName.result) {
      setFetch(false);
      setResultAlert(true);
    } else {
      setFetch(false);
      setResultAlert(pbResult);
    }
  }, [publishChannelName, pbResult]);
  const [fetch, setFetch] = React.useState(false);
  const [resultAlert, setResultAlert] = React.useState(false);

  const channelMapDifflist = () => {
    return 'There is no changes.';
  };
  const onConfirm = () => {
    const channelMapId = channelMappedData ? channelMappedData.channelMapId : null
    const saveType = channelMappedData ? channelMappedData.publishStatus : null
    let diffChannel = editedChannel.filter((item) => item.sChannelName !== item.pChannelName).map((item) => { return item.channel_idx })
    setPublishedData(true);
    setPublishOnConfirm(system, ric, cntry, channelMapId, saveType, feeds, cpChannelDataViewRow, diffChannel);
    //setPublishedData(false);
  };
  const allClose = () => {
    publishChannelName.result = false;
    setResultAlert(false);
    onClose();
  };

  return (
    <div>
      <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title" className={'pop_wrap publish_pop'}>
        <div className="MuiDialogTitle-root pop_head" id="form-dialog-title">
          <h2 className="MuiTypography-root MuiTypography-h6">
            Check Dialog
            <IconButton aria-label="close" className={'noTit_close'} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </h2>
        </div>
        <div className={'pop_body'}>
          <div className="tbl_wrap">

            <div className="preview_wrap">
              <div className="tbl_wrap publish_table">
                <div className="tbl_top_cont_wrap">
                  <div className="tbl_top_cont">
                    <p className="tit">AS-IS (status: Published)</p>
                    <p className="time">(last update date : {editedChannel.length != 0 ? editedChannel[0].pChgDate : ''})</p>
                  </div>
                  <div className="tbl_top_cont">
                    <p className="tit">TO-BE (status: Saved)</p>
                    <p className="time">(last update date : {editedChannel.length != 0 ? editedChannel[0].sChgDate : ''})</p>
                  </div>
                </div>
                <div className="tbl_wrap">

                </div>
                <Grid container spacing={2} style={{ margin: '1rem 0' }}>
                  <Grid item xs={6}>
                    <MaterialTable
                      data={editedChannel}
                      columns={[
                        {
                          title: 'Country',
                          field: 'pCountry',
                          alignItems: 'center',
                          width: '16%',
                        },
                        {
                          title: 'Ch. No',
                          field: 'pChannelNo',
                          alignItems: 'center',
                          width: '15%',
                        },
                        {
                          title: 'Ch. ID',
                          field: 'pChannelId',
                          alignItems: 'center',
                        },
                        {
                          title: 'Ch. Name',
                          field: 'pChannelName',
                          alignItems: 'left',
                        },
                      ]}
                      options={{
                        search: false,
                        paging: false,
                        showTitle: false,
                        doubleHorizontalScroll: false,
                        // maxBodyHeight: '460px',
                        // minBodyHeight: '460px',
                        rowStyle: (data, index) => ({
                          backgroundColor: data.sChannelName !== data.pChannelName ? '#fef5f8' : index % 2 === 0 ? '#fdfdfd' : '#fff',
                          color: data.sChannelName !== data.pChannelName ? '#d8295c' : '#484848',
                        }),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MaterialTable
                      columns={[
                        {
                          title: 'Country',
                          field: 'sCountry',
                          alignItems: 'center',
                          width: '16%',
                        },
                        {
                          title: 'Ch. No',
                          field: 'sChannelNo',
                          alignItems: 'center',
                          width: '15%',
                        },
                        {
                          title: 'Ch. ID',
                          field: 'sChannelId',
                          alignItems: 'center',
                        },
                        {
                          title: 'Ch. Name',
                          field: 'sChannelName',
                          alignItems: 'left',
                        },
                      ]}
                      data={editedChannel}
                      options={{
                        search: false,
                        paging: false,
                        showTitle: false,
                        doubleHorizontalScroll: false,
                        rowStyle: (data, index) => ({
                          backgroundColor: data.sChannelName !== data.pChannelName ? '#fef5f8' : index % 2 === 0 ? '#fdfdfd' : '#fff',
                          color: data.sChannelName !== data.pChannelName ? '#d8295c' : '#484848',
                        }),
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="btn_set_wrap">
              <Button disabled={editedChannel.length < 1} variant="contained" onClick={() => setOnPublish(true)} className="btn_color2">
                Publish
              </Button>
              <Button variant="contained" onClick={onClose} className="btn_color1">
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <Dialog open={onPublish} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
            {publishedData ? (
              <LoadingIndicator />
            ) : (
              <React.Fragment>
                <div className="ico_condition1"></div>
                <p className="pop_comment">Do you want to publish?</p>
                <DialogActions>
                  <div className="btn_set_wrap alC">
                    <Button color="primary" onClick={onConfirm} className="btn_color2">
                      OK
                    </Button>
                    <Button
                      autoFocus
                      color="primary"
                      onClick={() => setOnPublish(false)}
                      className="btn_color1">
                      Cancel
                    </Button>
                  </div>
                </DialogActions>
              </React.Fragment>
            )}
          </div>
        </Dialog>
        <Dialog open={false} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
            <p className="pop_comment">{''}</p>
            <DialogActions>
              <div className="btn_set_wrap alC">
                <Button color="primary" className="btn_color2">
                  OK
                </Button>
              </div>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog open={resultAlert} aria-labelledby="confirm-dialog">
          <div className="pop_size_small">
            <p className="pop_comment">{publishChannelName.msg}</p>
            <DialogActions>
              <div className="btn_set_wrap alC">
                <Button color="primary" onClick={allClose} className="btn_color2">
                  OK
                </Button>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </Dialog>
    </div>


  );
}
PublishEditedChannel.propTypes = {
};
const mapStateToProps = state => ({
  publishChannelName: state.channelMapping.publishChannelName,
  pbResult: state.channelMapping.pbchannelnameresult,
  channelMappedData: state.channelMapping.channelMappedData,
});
const mapDispatchToProps = dispatch => ({
  setPublishOnConfirm: (system, ric, cntry, channelMapId, saveType, feeds, cpChannelDataViewRow, diffChannel) => dispatch(setPublishOnConfirm(system, ric, cntry, channelMapId, saveType, feeds, cpChannelDataViewRow, diffChannel))
});
export default connect(mapStateToProps, mapDispatchToProps)(PublishEditedChannel);
